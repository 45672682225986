import { isEqual } from 'lodash';
import { timeUtils, utils } from '../../../helpers';
import { Audience, EntryMap, Store } from '../../../types';
import { RecipeStatus } from '../../recipes/recipeUtils';
import {
    Campaign,
    CampaignChannel,
    CampaignChannelsMap,
    CampaignChannelType,
    CampaignMap,
    EmailTemplate,
    SendgridDomainCheck,
    SMSTemplate,
} from './../../../types/campaigns';
import { DisabledMap, campaignHasRecipientsConfigured, getTriggers, hasCamaignSent, validateCampaigns, } from './cmp.builder.helpers';
import { TemplateFiltersState } from './components/template-browser/template.brower.types';

export type CampaignPermissions = {
    disableActivation?: boolean
}


export type SaveCampaignOption = "publish" | "de-draft"
export type CampaignBuilderPageProps = {
    isPreview?: boolean

    uid: string
    isActive: boolean
    isDisabled: (channel?: CampaignChannelType) => boolean
    disabledReasons: (channel?: CampaignChannelType) => string[]
    disabledMap?: DisabledMap

    lastSave?: number
    loaded: boolean

    isExternalEditor?: boolean // If this campaign is being viewed as a preview. Usually for recipe templates
    isInstaller?: boolean // If the viewer is an installer 

    isWaterfall: boolean
    setIsWaterfall: React.Dispatch<React.SetStateAction<boolean>>

    selectedStage: CampaignBuilderStage | undefined
    setSelectedStage: React.Dispatch<React.SetStateAction<CampaignBuilderStage | undefined>>

    selectedChannel: CampaignChannelType | undefined
    setSelectedChannel: React.Dispatch<React.SetStateAction<CampaignChannelType | undefined>>

    getCampaign: (channel?: CampaignChannelType) => Campaign
    updateCampaign: (campaign: Campaign, channel?: CampaignChannelType | true) => void
    updateChannel: (channel: CampaignChannelType, data: Partial<CampaignChannel>) => void

    saveCampaign: (option?: SaveCampaignOption) => void
    showCancel: boolean
    cancelProcess: () => void
    changeStage: (stage: CampaignBuilderStage, opts?: ChangeStageOpts) => boolean

    recipeStatus: RecipeStatus
    setRecipeStatus: React.Dispatch<React.SetStateAction<RecipeStatus>>

    channels: (ChannelInfo & CampaignChannel)[]
    channelMap: CmpChannelMap
    selectedCount: number

    stores: EntryMap<Store>;

    audiences: EntryMap<Audience>;
    setAudiences: React.Dispatch<React.SetStateAction<EntryMap<Audience>>>;

    config: CampaignBuilderConfig
    setConfig: React.Dispatch<React.SetStateAction<CampaignBuilderConfig>>

    sendgridDomainCheck?: SendgridDomainCheck | undefined

    textSegments: number | undefined
    setTextSegments: React.Dispatch<React.SetStateAction<number | undefined>>
}

export type CampaignBuilderChannelsProps = CampaignBuilderPageProps & {
    campaign: Campaign
    setCampaign: React.Dispatch<React.SetStateAction<Campaign>>
    campaigns: CampaignMap
    setCampaigns: React.Dispatch<React.SetStateAction<CampaignMap>>
    ogCampaigns?: CampaignMap
}
export type CampaignBuilderContentProps = CampaignBuilderPageProps & {
    sendgridDomainCheck?: SendgridDomainCheck | undefined
    setPreviewConfig?: React.Dispatch<React.SetStateAction<ContentPreviewConfig>>
}
export type CampaignBuilderRecipientsProps = CampaignBuilderPageProps & {
    isInformationMatching: () => boolean
}
export type CampaignBuilderReviewProps = CampaignBuilderPageProps & {
    campaign: Campaign
    campaigns: CampaignMap
    ogCampaigns: CampaignMap
    ogCampaign: Campaign
    setCampaigns: React.Dispatch<React.SetStateAction<CampaignMap>>

    clonedCampaign?: Campaign | undefined
    setClonedCampaign: React.Dispatch<React.SetStateAction<Campaign | undefined>>

    clonedCampaigns: CampaignMap
    setClonedCampaigns: React.Dispatch<React.SetStateAction<CampaignMap>>
}

export type CampaignBuilderStagesProps = {
    config: CampaignBuilderConfig
    isWaterfall: boolean
    campaign: Campaign
    campaigns: CampaignMap
    selectedStage: CampaignBuilderStage | undefined
    selectedChannel: CampaignChannelType | undefined
    channels: (ChannelInfo & CampaignChannel)[]
    selectedCount: number
    getCampaign: (channel?: CampaignChannelType) => Campaign
}

export type CampaignReviewPageSubProps = {}

export type CampaignChannelSelectorProps = {
    isWaterfall: boolean
    channel: CampaignChannelType
    isSelected: boolean
    priority: number
    maxPriorityAllowed: number

    toggleSelected: () => void
    setPriority: (priority: number) => boolean

    doesOverflow: boolean
    disabled?: boolean

    lockedReasons?: string[]
}
export type ChannelInfo = {
    channel: CampaignChannelType
    priority: number
    isSelected?: boolean
}

export type CmpChannelMap = {
    [key in CampaignChannelType]: {
        isSelected: boolean
        priority: number
    }
}

export type ToggleWaterfallConfig = {
    confirmed: boolean // They have confirmed that they want to switch
    copyTemplates?: boolean // They want to copy templates from the previous campaign if it exists
    copyTemplate?: CampaignChannelType // They want to copy a specific template from the previous campaign if it exists
    archivePrevious?: boolean // They want to archive the previous campaign if it exists
}


export const CampaignTypesMapping = {
    normal: {
        title: 'Normal',
        description: 'Send a one-off campaign to your audience',
        confirm: false,
        visisble() {
            return true
        },
        check: (campaign: Campaign): boolean => {
            const isRandomLottery = !!campaign.capPerDay
            const triggers = getTriggers(campaign)
            const hasTriggers = !!triggers && Object.values(triggers || {}).filter(Boolean).length > 0
            const isOptin = campaign.optinCampaign || campaign.emailOptinCampaign

            return !isRandomLottery && !hasTriggers && !isOptin
        },
        enable: (campaign: Campaign): Campaign => {
            campaign.capPerDay = undefined
            campaign.triggers = undefined
            campaign.optinCampaign = false
            campaign.emailOptinCampaign = false
            return campaign
        },
        getDefaultTemplateKeysFilters: (): TemplateFiltersState => {
            return {
                industries: [

                ],
                tags: [

                ],
                usage: [

                ]
            }
        }
    },
    abandonedCart: {
        title: 'Abandoned cart',
        description: 'Send a campaign to customers who have abandoned their cart',
        confirm: false,
        visisble() {
            return true
        },
        check: (campaign: Campaign): boolean => {
            return !!campaign.triggers?.abandonedCart
        },
        enable: (campaign: Campaign): Campaign => {
            campaign.capPerDay = undefined
            campaign.triggers = { abandonedCart: true }
            campaign.sendAtTimePredicted = false
            campaign.optinCampaign = false
            campaign.emailOptinCampaign = false

            // When enabling abandoned cart we also have to switch to ongoing
            campaign = CampaignScheduleTypesMapping.ongoing.enable(campaign)

            return campaign
        },
        getDefaultTemplateKeysFilters: (): TemplateFiltersState => {
            return {
                industries: [

                ],
                tags: [

                ],
                usage: [
                    "Abandoned Cart",
                ]
            }
        }
    },
    postPurchase: {
        title: 'Post-purchase survey or review',
        description: 'Send a campaign to customers who have recently purchased',
        confirm: false,
        visisble() {
            return true
        },
        check: (campaign: Campaign): boolean => {
            return !!campaign.triggers?.postSale
        },
        enable: (campaign: Campaign): Campaign => {
            campaign.capPerDay = undefined
            campaign.triggers = { postSale: true }
            campaign.sendAtTimePredicted = false
            campaign.optinCampaign = false
            campaign.emailOptinCampaign = false
            return campaign
        },
        getDefaultTemplateKeysFilters: (): TemplateFiltersState => {
            return {
                industries: [

                ],
                tags: [
                    "Post Purchase"
                ],
                usage: [
                    "Post Purchase"
                ]
            }
        }
    },
    birthdayReward: {
        title: 'Birthday award',
        description: 'Send a campaign to customers on their birthday',
        confirm: false,
        visisble() {
            return true
        },
        check: (campaign: Campaign): boolean => {
            return !!campaign.triggers?.birthday
        },
        enable: (campaign: Campaign): Campaign => {
            campaign.capPerDay = undefined
            campaign.triggers = { birthday: true }
            campaign.sendAtTimePredicted = false
            campaign.optinCampaign = false
            campaign.emailOptinCampaign = false
            return campaign
        },
        getDefaultTemplateKeysFilters: (): TemplateFiltersState => {
            return {
                industries: [

                ],
                tags: [
                    "Birthday",
                ],
                usage: [

                ]
            }
        }
    },
    randomLottery: {
        title: 'Random lottery',
        description: 'Send a campaign to a random selection of your audience per day',
        confirm: false,
        visisble() {
            return true
        },
        check: (campaign: Campaign): boolean => {
            return !!campaign.capPerDay
        },
        enable: (campaign: Campaign): Campaign => {
            campaign.capPerDay = 1
            campaign.triggers = undefined
            campaign.sendAtTimePredicted = false
            campaign.optinCampaign = false
            campaign.emailOptinCampaign = false
            return campaign
        },
        getDefaultTemplateKeysFilters: (): TemplateFiltersState => {
            return {
                industries: [

                ],
                tags: [

                ],
                usage: [

                ]
            }
        }
    },
    smsOptin: {
        title: 'SMS opt-in',
        description: 'This feature has been enabled via a manual override by Alpine IQ staff. Switch on at your own risk!',
        confirm: true,
        visisble() {
            const showOptInToggle = ['1062', '1099', '1159', '1002', '1243', '1653', '2182', '1345', '2932', '2692', '3384']
            return utils.isDevUID() || showOptInToggle.includes(utils.uid)
        },
        check: (campaign: Campaign): boolean => {
            return !!campaign.optinCampaign
        },
        enable: (campaign: Campaign): Campaign => {
            campaign.optinCampaign = true
            campaign.emailOptinCampaign = false
            if (campaign.templates) {
                campaign.templates = {
                    ...campaign.templates,
                    landingType: 'RAW',
                } as SMSTemplate
            }
            return campaign
        },
        getDefaultTemplateKeysFilters: (): TemplateFiltersState => {
            return {
                industries: [

                ],
                tags: [

                ],
                usage: [

                ]
            }
        }
    },
    emailOptin: {
        title: 'Email opt-in',
        description: 'This feature has been enabled via a manual override by Alpine IQ staff. Switch on at your own risk!',
        confirm: true,
        visisble() {
            return (utils.user?.disableImplicitEmail || utils.isDevUID())
        },
        check: (campaign: Campaign): boolean => {
            return !!campaign.emailOptinCampaign
        },
        enable: (campaign: Campaign): Campaign => {
            campaign.optinCampaign = false
            campaign.emailOptinCampaign = true
            return campaign
        },
        getDefaultTemplateKeysFilters: (): TemplateFiltersState => {
            return {
                industries: [

                ],
                tags: [

                ],
                usage: [

                ]
            }
        }
    },
} as const

export type CampaignType = keyof typeof CampaignTypesMapping

export const CampaignScheduleTypesMapping = {
    blast: {
        icon: 'rocket',
        title: 'Blast',
        description: 'Campaign starts sending to all recipients at the defined start time',
        show: (args: CampaignBuilderStagesProps) => true,
        check: (campaign: Campaign): boolean => {
            if (campaign.enableQueue) return false
            const isBlast = !!campaign.blast || (campaign.blast === undefined && !campaign.id);
            return isBlast && !campaign.dateRange?.active
        },
        enable: (campaign: Campaign): Campaign => {
            // campaign.scheduled = 0;
            campaign.blast = true;
            campaign.enableQueue = false;
            campaign.recurDays = -1;
            campaign.dateRange = undefined;
            return campaign
        }
    },
    ongoing: {
        icon: 'arrows-rotate',
        title: 'Ongoing',
        description: 'Campaign starts sending to recipients as soon as they are added after the defined start time',
        show: (args: CampaignBuilderStagesProps) => true,
        check: (campaign: Campaign): boolean => {
            const isBlast = !!campaign.blast || (campaign.blast === undefined && !campaign.id);
            return !isBlast && !campaign?.dateRange?.active
        },
        enable: (campaign: Campaign): Campaign => {
            // campaign.scheduled = 0;
            campaign.blast = false;
            campaign.enableQueue = false;
            campaign.recurDays = -1;
            campaign.dateRange = { active: false, start: 0, end: 0 };
            return campaign
        }
    },
    startEnd: {
        icon: 'flag-checkered',
        title: 'Start & End',
        description: 'Campaign starts sending to recipients at the defined start time and anyone until the end time',
        show: (args: CampaignBuilderStagesProps) => true,
        check: (campaign: Campaign): boolean => {
            return !!campaign.dateRange && !!campaign.dateRange.active
        },
        enable: (campaign: Campaign): Campaign => {
            campaign.enableQueue = false;
            campaign.scheduled = 0;
            campaign.recurDays = -1;
            campaign.blast = false;
            if (campaign.dateRange) campaign.dateRange.active = true;
            else campaign.dateRange = { active: true, };
            return campaign
        }
    },
    queue: {
        icon: 'paper-plane-top',
        title: 'Queue',
        description: 'Send messages to clients one at a time',
        show: (args: CampaignBuilderStagesProps) => {
            // This cannot be used if there are any non - SMS channels
            for (const channel of args.channels) {
                if (channel.channel !== 'templates' && channel.inUse) return false
            }
            return utils.isDevUID() || utils.user.profile?.industry === 'political'
        },
        check: (campaign: Campaign): boolean => {
            return !!campaign.enableQueue
        },
        enable: (campaign: Campaign): Campaign => {
            campaign.enableQueue = true;
            campaign.scheduled = Math.floor(Date.now() / 1000) - timeUtils.units.MINUTE_S * 5
            campaign.blast = true;
            campaign.recurDays = -1;
            campaign.dateRange = undefined;
            return campaign
        }
    }
}

export type CampaignScheduleTypes = keyof typeof CampaignScheduleTypesMapping

export type ChannelInput = ({
    isWaterfall?: false | undefined
    campaign: Campaign
    campaigns: CampaignMap
    ogCampaigns?: CampaignMap
} | {
    isWaterfall: true
    campaign: Campaign
    ogCampaign?: Campaign
})
type Errors = string[]
export const CampaignBuilderStages = {
    channels: {
        index: 0,
        name: "Channels",
        // This is the first stage so it has always started
        hasStarted: (args: CampaignBuilderStagesProps): boolean => {
            return true
        },
        // Stage is considered completed after channels have been selected
        // and the user has moved on to the next stage
        hasCompleted: (args: CampaignBuilderStagesProps): boolean => {
            const isCompleted = CampaignBuilderStages.channels.checkCompleted(args).length === 0;
            const isOnChannelStage = args.selectedStage === 'channels';
            return isCompleted && !isOnChannelStage
        },
        checkCompleted: (args: CampaignBuilderStagesProps): Errors => {
            const hasChannels = args.selectedCount > 0;
            if (!hasChannels) return ['You must select at least one channel before continuing']
            return []
        }
    },
    recipients: {
        index: 1,
        name: "Recipients",
        // This stage has started if the user has selected this stage
        // OR if the user has already completed the stage
        hasStarted: (args: CampaignBuilderStagesProps): boolean => {
            const isMatching = args.config.sameInformation
            const isOnRecipientsStage = args.selectedStage === 'recipients';
            const hasCompleted = CampaignBuilderStages.recipients.hasCompleted(args);
            // IF campaign has a name then we have started
            if (args.isWaterfall || isMatching) {
                const campaign = args.getCampaign();
                if (!!campaign.name || !!campaign.audiences?.length) return true
            }
            else {
                for (const campaign of Object.values(args.campaigns)) {
                    if (!!campaign.name || !!campaign.audiences?.length) return true
                }
            }
            return isOnRecipientsStage || hasCompleted
        },
        // Stage is considered completed after recipients have been selected (for each campaign)
        hasCompleted: (args: CampaignBuilderStagesProps): boolean => {
            const isCompleted = CampaignBuilderStages.recipients.checkCompleted(args).length === 0;
            const isOnRecipientsStage = args.selectedStage === 'recipients';
            return isCompleted && !isOnRecipientsStage
        },
        checkCompleted: (args: CampaignBuilderStagesProps): Errors => {
            const isMatching = args.config.sameInformation
            // If waterfall only need to check one campaign
            // Also IF sameInformation is true then we only need to check one campaign
            if (args.isWaterfall || isMatching) {
                const campaign = args.getCampaign();
                const errs = campaignHasRecipientsConfigured(campaign);
                if (!!errs.length) return errs
                return []
            }
            // If we have no campaigns then we have not started
            if (Object.keys(args.campaigns).length === 0) {
                return ['Could not find any campaigns']
            }
            const errors: Errors = []
            // Otherwise we need to check each campaign has completed
            for (const [channel, campaign] of (Object.entries(args.campaigns) as [CampaignChannelType, Campaign][])) {
                const errs = campaignHasRecipientsConfigured(campaign);
                if (!!errs.length) {
                    const channelName = CampaignChannelsMap[channel].shortName
                    errors.push(...errs.map(err => `${channelName} ${err}`))
                }
            }
            return errors
        }
    },
    content: {
        index: 2,
        name: "Content",
        // This stage has started if the user has selected this stage
        // OR if the user has already completed the stage
        hasStarted: (args: CampaignBuilderStagesProps): boolean => {
            const isOnContentStage = args.selectedStage === 'content';
            const hasCompletedPrev = CampaignBuilderStages.recipients.hasCompleted(args);
            // IF any of the channels have content then we have started
            const channels = args.channels.filter(channel => channel.isSelected);
            for (const channel of channels) {
                const channelInfo = CampaignChannelsMap[channel.channel];
                const hasContent = channelInfo.hasContent(channel, args.getCampaign(channel.channel));
                if (hasContent) return true
            }
            return isOnContentStage || hasCompletedPrev
        },
        // Stage is considered completed after all selected channels have content and are valid
        hasCompleted: (args: CampaignBuilderStagesProps): boolean => {
            const isCompleted = CampaignBuilderStages.content.checkCompleted(args);
            const isOnContentStage = args.selectedStage === 'content';
            return isCompleted.length === 0 && !isOnContentStage
        },
        checkCompleted: (args: CampaignBuilderStagesProps): Errors => {
            const errors: Errors = []
            const channels = args.channels.filter(channel => channel.isSelected);
            // IF no channels are selected then we have not started
            if (channels.length === 0) {
                return ['Could not find any channels']
            }
            // Loop over each channel and check if it has content/validate
            for (const channel of channels) {
                const channelInfo = CampaignChannelsMap[channel.channel];
                // const isValid = channelInfo.validate(channel, args.getCampaign(channel.channel));
                const hasContent = channelInfo.hasContent(channel, args.getCampaign(channel.channel));
                // if (!isValid || !hasContent) {
                if (!hasContent) {
                    errors.push(`Content must be configured for ${channelInfo.shortName}`)
                }
            }
            return errors
        }
    },
    review: {
        index: 3,
        name: "Review",
        // This stage has started if the user has selected this stage
        // OR if the user has already completed the stage
        hasStarted: (args: CampaignBuilderStagesProps): boolean => {
            const isOnReviewStage = args.selectedStage === 'review';
            const hasCompleted = CampaignBuilderStages.content.hasCompleted(args);
            return isOnReviewStage || hasCompleted
        },
        // Stage is considered completed after campaign is validated and all steps are completed
        hasCompleted: (args: CampaignBuilderStagesProps): boolean => {
            const isCompleted = CampaignBuilderStages.review.checkCompleted(args).length === 0;
            const isOnReviewStage = args.selectedStage === 'review'

            let hasMessage = false;

            // Check if campaign has sent a message
            if (args.isWaterfall) {
                const campaign = args.getCampaign();
                hasMessage = hasCamaignSent(campaign)
            } else {
                for (const campaign of Object.values(args.campaigns)) {
                    hasMessage = hasCamaignSent(campaign)
                    if (hasMessage) break;
                }
            }

            return isCompleted && (!isOnReviewStage || hasMessage)
        },
        checkCompleted: (args: CampaignBuilderStagesProps): Errors => {
            let lastSend = args.campaign.lastMessage || 0
            if (!args.isWaterfall) {
                for (const campaign of Object.values(args.campaigns)) {
                    if (campaign.lastMessage && campaign.lastMessage > lastSend) lastSend = campaign.lastMessage
                }
            }
            // Check if campaign is valid
            return (lastSend && !utils.isLocal()) ? [] : validateCampaigns(args, true)
        }
    },
} as const;

export type CampaignBuilderStage = keyof typeof CampaignBuilderStages;

export type CampaignBuilderConfig = {
    sameSchedule?: boolean
    sameInformation?: boolean
}

export type ChangeStageOpts = { ignoreErrors?: boolean, }

export type ContentPreviewConfig = { type: "" } | ({
    type: 'email'
    template: EmailTemplate
} | {
    type: 'text'
    template: SMSTemplate
})

export const CampaignBuilderTextOptions = {
    regular: {
        title: () => 'Regular SMS/MMS',
        isSelected(campaign: Campaign): boolean {
            const template: SMSTemplate = campaign.templates || {}
            return template.optionType === 'regular'
        },
        isAvailable(campaign: Campaign): boolean {
            const industry = utils.user.profile?.industry
            if ((industry === 'cannabis' || industry === 'brewery') && !utils.user.disableWalledGarden) return false
            return !utils.hasRestrictedIndustry10DLC()
        },
        isDisabled(campaign: Campaign): boolean {
            return !!campaign.optinCampaign
        }
    },
    regulated: {
        title: () => {
            const industry = utils.user.profile?.industry
            if (industry === 'cannabis' || industry === 'brewery') {
                return 'Regulated industry text'
            }
            return "Text with landing page"
        },
        isSelected(campaign: Campaign): boolean {
            const template: SMSTemplate = campaign.templates || {}
            return template.optionType === 'regulated' || (!template.mmsOnly && !template.optionType)
        },
        isAvailable(campaign: Campaign): boolean {
            return !utils.hasRestrictedIndustry10DLC()
        },
        isDisabled(campaign: Campaign): boolean {
            return false
        }
    },
    "mms-only": {
        title: () => 'MMS Image only',
        isSelected(campaign: Campaign): boolean {
            const template = campaign.templates || {}
            return template.optionType === 'mms-only' || (!!template.mmsOnly && !template.optionType)
        },
        isAvailable(campaign: Campaign): boolean {
            return !utils.hasRestrictedIndustry10DLC()
            // const whiteListMMSOnly = (['1034', '2538', '1048', '2627', '1925', '2452', '2479', '2481', '2312', '2876', '2778', '2480', '1281'])
            // const canViewMMSOnly = (whiteListMMSOnly.includes(utils.uid))
            // return canViewMMSOnly
        },
        isDisabled(campaign: Campaign): boolean {
            return !!campaign.optinCampaign
        }
    },
    "regulated-10dlc": {
        title: () => '10DLC Regulated industry text',
        isSelected(campaign: Campaign): boolean {
            return utils.hasRestrictedIndustry10DLC()
        },
        isAvailable(campaign: Campaign): boolean {
            return utils.hasRestrictedIndustry10DLC()
        },
        isDisabled(campaign: Campaign): boolean {
            return !!campaign.optinCampaign
        }
    },
    walletPass: {
        title: () => {
            return "Wallet pass text"
        },
        isSelected(campaign: Campaign): boolean {
            return false
        },
        isAvailable(campaign: Campaign): boolean {
            return false;
        },
        isDisabled(campaign: Campaign): boolean {
            return false
        }
    }
} as const

export type CampaignBuilderTextOption = keyof typeof CampaignBuilderTextOptions


export interface CampaignPreview {
    segments: number,
    body: string;
    landingHTML: string;
    promoScore?: {
        score: number
        breakdown: string
    }
}


export interface StaticProduct {
    imageUrl?: string
    productName?: string
    slug?: string
    salesPrice?: number
}



export const ClonedCampaignTrackerFields = {
    'name': {
        name: 'Name',
        description: 'The name of your campaign has not been updated',
        onClick: (props: CampaignBuilderPageProps) => {
            props.setSelectedStage('recipients')
        },
        isSame: (campaign: Campaign, ogCampaign: Campaign): boolean => {
            const ogName = ogCampaign.name || ''
            const name = campaign.name || ''
            return !!ogName && (name === ogName)
        }
    },
    'recipients': {
        name: 'Recipients ',
        description: 'The recipients of your campaign have not been updated',
        onClick: (props: CampaignBuilderPageProps) => {
            props.setSelectedStage('recipients')
        },
        isSame: (campaign: Campaign, ogCampaign: Campaign): boolean => {
            const ogAudiences = ogCampaign.audiences || []
            const audiences = campaign.audiences || []

            if (!isEqual(ogAudiences, audiences)) return false

            const ogExcluded = ogCampaign.exclude || []
            const excluded = campaign.exclude || []

            if (!isEqual(ogExcluded, excluded)) return false

            const ogStores = ogCampaign.storeIDs || []
            const stores = campaign.storeIDs || []

            if (!isEqual(ogStores, stores)) return false

            const ogStoreHash = ogCampaign.storeIDHash || {}
            const storeHash = campaign.storeIDHash || {}

            if (!isEqual(ogStoreHash, storeHash)) return false

            return true
        }
    },
    'email_subject': {
        name: 'Email subject',
        description: 'The subject line of your email channel has not been updated',
        onClick: (props: CampaignBuilderPageProps) => {
            props.setSelectedChannel('emailTemplate')
            props.setSelectedStage('content')
        },
        isSame: (campaign: Campaign, ogCampaign: Campaign): boolean => {
            const template = campaign.emailTemplate || {}
            const ogTemplate = ogCampaign.emailTemplate || {}
            return !!ogTemplate?.subject && (template?.subject === ogTemplate?.subject)
        }
    },
    'email_content': {
        name: 'Email content',
        description: 'The content of your email channel has not been updated',
        onClick: (props: CampaignBuilderPageProps) => {
            props.setSelectedChannel('emailTemplate')
            props.setSelectedStage('content')
        },
        isSame: (campaign: Campaign, ogCampaign: Campaign): boolean => {
            const template = campaign.emailTemplate || {}
            const ogTemplate = ogCampaign.emailTemplate || {}
            return !!ogTemplate?.html && (template?.html === ogTemplate?.html)
        }
    },
    'text_mms_image': {
        name: 'Text MMS image',
        description: 'The image of your text channel has not been updated',
        onClick: (props: CampaignBuilderPageProps) => {
            props.setSelectedChannel('templates')
            props.setSelectedStage('content')
        },
        isSame: (campaign: Campaign, ogCampaign: Campaign): boolean => {
            const template = campaign.templates || {}
            const ogTemplate = ogCampaign.templates || {}
            return !!ogTemplate.mmsMediaURL && (template.mmsMediaURL === ogTemplate.mmsMediaURL)
        }
    },
    'text_message_body': {
        name: 'Text message body',
        description: 'The body of your text channel has not been updated',
        onClick: (props: CampaignBuilderPageProps) => {
            props.setSelectedChannel('templates')
            props.setSelectedStage('content')
        },
        isSame: (campaign: Campaign, ogCampaign: Campaign): boolean => {
            const template = campaign.templates || {}
            const ogTemplate = ogCampaign.templates || {}
            return (
                !!ogTemplate.body && (template.body === ogTemplate.body) ||
                !!ogTemplate.msgContainer && (template.msgContainer === ogTemplate.msgContainer)
            )
        }
    },
    'text_landing_page': {
        name: 'Text landing page',
        description: 'The landing page of your text channel has not been updated',
        onClick: (props: CampaignBuilderPageProps) => {
            props.setSelectedChannel('templates')
            props.setSelectedStage('content')
        },
        isSame: (campaign: Campaign, ogCampaign: Campaign): boolean => {
            const template = campaign.templates || {}
            const ogTemplate = ogCampaign.templates || {}
            return !!ogTemplate.landingHTML && (template.landingHTML === ogTemplate.landingHTML)
        }
    },
    'voice_ttv_message': {
        name: 'Text to voice message',
        description: 'The text to voice message of your voice channel has not been updated',
        onClick: (props: CampaignBuilderPageProps) => {
            props.setSelectedChannel('voiceTemplate')
            props.setSelectedStage('content')
        },
        isSame: (campaign: Campaign, ogCampaign: Campaign): boolean => {
            const template = campaign.voiceTemplate || {}
            const ogTemplate = ogCampaign.voiceTemplate || {}
            return !!ogTemplate.ttv && (template.ttv === ogTemplate.ttv)
        }
    },
    'voice_recording': {
        name: 'Voice audio file/recording',
        description: 'The voice audio for your voice channel has not been updated',
        onClick: (props: CampaignBuilderPageProps) => {
            props.setSelectedChannel('voiceTemplate')
            props.setSelectedStage('content')
        },
        isSame: (campaign: Campaign, ogCampaign: Campaign): boolean => {
            const template = campaign.voiceTemplate || {}
            const ogTemplate = ogCampaign.voiceTemplate || {}
            return !!ogTemplate.voiceFile && (template.voiceFile === ogTemplate.voiceFile)
        }
    },
    'push_image': {
        name: 'Push image',
        description: 'The image of your push channel has not been updated',
        onClick: (props: CampaignBuilderPageProps) => {
            props.setSelectedChannel('pushTemplate')
            props.setSelectedStage('content')
        },
        isSame: (campaign: Campaign, ogCampaign: Campaign): boolean => {
            const template = campaign.pushTemplate || {}
            const ogTemplate = ogCampaign.pushTemplate || {}
            return !!ogTemplate.mediaURL && (template.mediaURL === ogTemplate.mediaURL)
        }
    },
    'push_subject': {
        name: 'Push subject',
        description: 'The subject line of your push channel has not been updated',
        onClick: (props: CampaignBuilderPageProps) => {
            props.setSelectedChannel('pushTemplate')
            props.setSelectedStage('content')
        },
        isSame: (campaign: Campaign, ogCampaign: Campaign): boolean => {
            const template = campaign.pushTemplate || {}
            const ogTemplate = ogCampaign.pushTemplate || {}
            return !!ogTemplate.title && (template.title === ogTemplate.title)
        }
    },
    'push_content': {
        name: 'Push content',
        description: 'The content of your push channel has not been updated',
        onClick: (props: CampaignBuilderPageProps) => {
            props.setSelectedChannel('pushTemplate')
            props.setSelectedStage('content')
        },
        isSame: (campaign: Campaign, ogCampaign: Campaign): boolean => {
            const template = campaign.pushTemplate || {}
            const ogTemplate = ogCampaign.pushTemplate || {}
            return !!ogTemplate.body && (template.body === ogTemplate.body)
        }
    },
    'browser_push_image': {
        name: 'Browser push image',
        description: 'The image of your browser push channel has not been updated',
        onClick: (props: CampaignBuilderPageProps) => {
            props.setSelectedChannel('browserTemplate')
            props.setSelectedStage('content')
        },
        isSame: (campaign: Campaign, ogCampaign: Campaign): boolean => {
            const template = campaign.browserTemplate || {}
            const ogTemplate = ogCampaign.browserTemplate || {}
            return !!ogTemplate.mediaURL && (template.mediaURL === ogTemplate.mediaURL)
        }
    },
    'browser_push_subject': {
        name: 'Browser push subject',
        description: 'The subject line of your browser push channel has not been updated',
        onClick: (props: CampaignBuilderPageProps) => {
            props.setSelectedChannel('browserTemplate')
            props.setSelectedStage('content')
        },
        isSame: (campaign: Campaign, ogCampaign: Campaign): boolean => {
            const template = campaign.browserTemplate || {}
            const ogTemplate = ogCampaign.browserTemplate || {}
            return !!ogTemplate.title && (template.title === ogTemplate.title)
        }
    },
    'browser_push_content': {
        name: 'Browser push content',
        description: 'The content of your browser push channel has not been updated',
        onClick: (props: CampaignBuilderPageProps) => {
            props.setSelectedChannel('browserTemplate')
            props.setSelectedStage('content')
        },
        isSame: (campaign: Campaign, ogCampaign: Campaign): boolean => {
            const template = campaign.browserTemplate || {}
            const ogTemplate = ogCampaign.browserTemplate || {}
            return !!ogTemplate.body && (template.body === ogTemplate.body)
        }
    },
} as const

export type ClonedCampaignTrackerField = keyof typeof ClonedCampaignTrackerFields
